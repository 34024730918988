<style scoped>
body {
  background-color: whitesmoke;
}

table,
th,
td {
  border: 1px solid black;
}

.font_style_table {
  font-family: monospace;
  font-size: 18px;
  background-color: white;
}

.print_style_table {
  font-family: monospace;
  font-size: 14px;
  background-color: white;
}

.inside_table {
  border-collapse: collapse;
  margin: -1px;
}

.mtable {
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 20cm;
}

th,
tr,
td {
  padding-left: 3px;
  padding-right: 3px;
}

.rowheader {
  text-align: center;
}

@page {
  size: 21cm 29.7cm;
  margin: 1mm;
  /* change the margins as you want them to be. */
}

@media print {
  body {
    width: 21cm;
    height: 99.99%;
    overflow: hidden;
    /* change the margins as you want them to be. */
  }
}
</style>
<template>
  <div>
    <table class="mtable" id="mtable">
      <thead>
        <tr style="padding: 0px">
          <td colspan="7" style="padding: 0px">
            <table class="inside_table font_style_table">
              <tbody>
                <tr>
                  <th scope="col" style="text-align: left">Name</th>
                  <td id="customer_name" style="font-weight: bold">
                    {{ quote.name }}
                  </td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">Loan Amount</th>
                  <td id="principal">{{ quote.principal | toCurrencyINR }}</td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">Rate of interest</th>
                  <td id="mroi">
                    {{ quote.monthly_interest }}% Monthly (Fixed)
                  </td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">Tenure</th>
                  <td id="tenure">{{ quote.tenure }} MONTHS</td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">EMI</th>
                  <td id="emi">{{ quote.emi | toCurrencyINR }}</td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">Total repayment</th>
                  <td id="tpay">{{ quote.total_repayment | toCurrencyINR }}</td>
                </tr>
                <template v-if="quote.has_residual">
                  <tr id="residual">
                    <th scope="row" style="text-align: left">
                      Residual start date
                    </th>
                    <td id="residual_start_date">
                      {{ quote.residual_start_date }}
                    </td>
                  </tr>
                  <tr id="residual">
                    <th scope="row" style="text-align: left">
                      Residual end date
                    </th>
                    <td id="residual_end_date">
                      {{ quote.residual_end_date }}
                    </td>
                  </tr>
                  <tr id="residual">
                    <th scope="row" style="text-align: left">Residual days</th>
                    <td id="residual_days">{{ quote.residual_days }}</td>
                  </tr>
                  <tr id="residual">
                    <th scope="row" style="text-align: left">
                      Residual interest
                    </th>
                    <td id="residual_interest">
                      {{ quote.residual_interest }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <th scope="row" style="text-align: left">
                    Disbursement date
                  </th>
                  <td id="disbursement_date">{{ quote.disbursement_date }}</td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">
                    First EMI due date
                  </th>
                  <td id="first_due">{{ quote.first_due_date }}</td>
                </tr>
                <tr>
                  <th scope="row" style="text-align: left">
                    Last EMI due date
                  </th>
                  <td id="last_due">{{ quote.last_due_date }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </thead>
      <thead class="font_style_table">
        <tr>
          <th scope="col" colspan="7" style="text-align: center">Schedule</th>
        </tr>
      </thead>
      <thead class="font_style_table">
        <tr>
          <th scope="col" class="rowheader" style="width: 30px">#</th>
          <th scope="col" class="rowheader" style="width: 100px">Date</th>
          <th scope="col" class="rowheader" style="width: 80px">Principal</th>
          <th scope="col" class="rowheader" style="width: 80px">Interest</th>
          <th scope="col" class="rowheader" style="width: 80px">EMI</th>
          <th scope="col" class="rowheader" style="width: 80px">Balance</th>
          <th scope="col" class="rowheader" style="width: 50px">Remarks</th>
        </tr>
      </thead>
      <tbody id="rows" class="font_style_table">
        <tr id="unit_row" v-for="item in quote.repayments" :key="item.idx">
          <td style="text-align: right" id="nth">{{ item.idx }}</td>
          <td style="text-align: center" id="payment_date">
            {{ item.payment_date }}
          </td>
          <td style="text-align: right" id="principalm">
            {{ item.rpp | toCurrencyINR }}
          </td>
          <td style="text-align: right" id="interesetm">
            {{ item.rpi | toCurrencyINR }}
          </td>
          <td style="text-align: right" id="amountm">
            {{ item.rpt | toCurrencyINR }}
          </td>
          <td style="text-align: right" id="balancem">
            {{ item.rpb | toCurrencyINR }}
          </td>
          <td style="text-align: right" id="remarksm"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  data() {
    return {
      quote: null,
    };
  },
  created() {
    console.log("Created schedule");
    this.quote = JSON.parse(localStorage.getItem("quote") || "{}");
  },
  mounted() {
    console.log("Mounted shcedule");
    if (process.env.NODE_ENV !== "development") {
      document.querySelector("head > link:nth-child(9)").remove();
    }
    window.print();
  },
};
</script>
