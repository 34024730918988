<template>
  <v-app>
    <div v-if="!showSchedule">
      <v-navigation-drawer v-model="drawer" absolute left temporary app>
        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <v-list-item to="/">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item to="/fixed_rate">
              <v-list-item-title>Fixed Rate Calculator</v-list-item-title>
            </v-list-item>
            <v-list-item to="/">
              <v-list-item-title>EMI Calculator</v-list-item-title>
            </v-list-item>
            <v-list-item to="/amazon_deals">
              <v-list-item-title>Amazon deals</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-system-bar color="deep-purple darken-3"></v-system-bar>
      <v-app-bar app color="deep-purple accent-4" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="cursor: pointer" @click="$router.push('/')"
          >Abbas</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon class="">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon light-4>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </div>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
  }),
  computed: {
    showSchedule() {
      return this.$route.name === "schedule";
    },
  },
};
</script>
