<template>
  <div id="amazon"></div>
</template>
<script>
import postscribe from 'postscribe';

export default {
  name: "AmazonMonitize",
  mounted() {
    var amz = document.createElement("script");
    amz.innerHTML= [
      "var aax_size='300x250';",
      "var aax_pubname = 'abbazs-21';",
      "var aax_src='302';"].join("\n");
    var amk = document.createElement("script");
    amk.setAttribute("src", "https://c.amazon-adsystem.com/aax2/assoc.js");
    postscribe("#amazon",amz.outerHTML + amk.outerHTML);
  }
};
</script>