import Vue from "vue";
import Router from "vue-router";
import emi_reducing_balance from "../components/emi_reducing_balance.vue";
import emi_reducing_balance_fixed from "../components/emi_reducing_balance_fixed.vue";
import amazon_deals from "../components/AmazonDeals.vue";
import schedule from "../components/Schedule.vue"

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
          component: emi_reducing_balance,
    },
    {
      path: "/fixed_rate",
      name: "Fixed Rate",
        component: emi_reducing_balance_fixed,
    },
    {
      path: "/amazon_deals",
      name: "Amazon deals",
        component: amazon_deals,
    },
    {
        path:"/schedule",
        name:"schedule",
        component: schedule
    }
  ],
});
