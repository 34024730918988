<template>
<v-card elevation="5" class="mx-auto" max-width="900">
  <div id="amazondeals">
  </div>
</v-card>
</template>
<script>
import postscribe from "postscribe";

export default {
  name: "AmazonDeals",
  mounted() {
    var adiv = document.getElementById("amazondeals");
    var amz = document.createElement("script");
    amz.innerHTML = [
      `amzn_assoc_placement ="";`,
      `amzn_assoc_search_type = "search_widget";`,
      `amzn_assoc_width ="300";`,
      `amzn_assoc_height ="250";`,
      `amzn_assoc_default_search_category ="";`,
      `amzn_assoc_region ="IN";`,
      `amzn_assoc_tracking_id ="abbazs-21";`,
      `amzn_assoc_default_search_key ="deals";`,
      `amzn_assoc_theme ="light";`,
      `amzn_assoc_bg_color ="FFFFFF";`,
      `amzn_assoc_marketplace ="amazon";`,
      `amzn_assoc_ad_type ="responsive_search_widget";`,
    ].join("\n");
    adiv.appendChild(amz);
    var amk = document.createElement("script");
    amk.setAttribute("src", "https://z-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1&Marketplace=IN");
    postscribe("#amazondeals", amk.outerHTML);
  },
};
</script>
