<template>
  <div>
    <v-card elevation="5" class="mx-auto" max-width="380">
      <v-card-title
        primary-title
        class="justify-center deep-purple accent-1 white--text"
        >EMI Calculator</v-card-title
      >
      <v-card-text>
        <v-row align="center" class="mx-4 my-0" style="height: 50px">
          <v-col>
            <div class="black--text text-right font-weight-black">
              Loan amount
            </div>
          </v-col>
          <v-col>
            <vuetify-money v-model="Amount" />
          </v-col>
        </v-row>
        <v-row align="center" class="mx-4 my-0" style="height: 50px">
          <v-col
            ><div class="black--text text-right font-weight-black">
              Rate of interest
            </div></v-col
          ><v-col>
            <v-text-field
              v-model="RateOfInterest"
              type="number"
              suffix="%"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row align="center" class="mx-4 my-0" style="height: 50px">
          <v-col
            ><div class="black--text text-right font-weight-black">
              Tenure
            </div></v-col
          ><v-col>
            <v-text-field suffix="Months" v-model="Tenure"></v-text-field
          ></v-col>
        </v-row>
        <v-row align="center" class="mx-4 my-0" style="height: 50px">
          <v-col
            ><div class="black--text text-right font-weight-black">
              EMI
            </div></v-col
          ><v-col><vuetify-money v-model="EMI"/></v-col>
        </v-row>
        <v-row justify="space-around" class="mx-4 my-5">
          <v-chip-group
            v-model="selection"
            active-class="deep-purple accent-4 white--text"
            class="align-center"
            column
            mandatory
          >
            <v-chip @click="calculate_result(0)">EMI</v-chip>

            <v-chip @click="calculate_result(1)">RATE</v-chip>

            <v-chip @click="calculate_result(2)">TENURE</v-chip>

            <v-chip @click="calculate_result(3)">AMOUNT</v-chip>
          </v-chip-group>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="5" class="mx-auto my-7" max-width="380">
      <amazon-monitize></amazon-monitize>
    </v-card>
    <v-card elevation="5" class="mx-auto my-7" max-width="510">
      <v-card-title
        primary-title
        class="justify-center m-0 deep-purple accent-1 white--text"
        >Repayment Schedule</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-text-field v-model="name" label="Name"></v-text-field>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="disbursement_date_menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="DisbursementDate"
                  label="Disbursement date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                label="Disbursement date"
                v-model="DisbursementDate"
                @input="disbursement_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="firstemi_date_menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="FirstEMIDate"
                  label="First EMI date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                label="First EMI date"
                v-model="FirstEMIDate"
                @input="firstemi_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn outlined rounded text @click="schedule">
            Generate schedule
          </v-btn>
          <v-btn outlined rounded text @click="print_document"> Print </v-btn>
        </v-card-actions>
        <v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Principal</th>
                  <th class="text-left">Interest</th>
                  <th class="text-left">EMI</th>
                  <th class="text-left">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in repayments" :key="item.idx">
                  <td class="text-right">{{ item.idx }}</td>
                  <td>{{ item.payment_date }}</td>
                  <td>{{ item.rpp | toCurrencyINR }}</td>
                  <td>{{ item.rpi | toCurrencyINR }}</td>
                  <td>{{ item.rpt | toCurrencyINR }}</td>
                  <td>{{ item.rpb | toCurrencyINR }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VuetifyMoney from "./VuetifyMoney";
import AmazonMonitize from "./AmazonMonitize.vue";

var Finance = require("tvm-financejs");
var finance = new Finance();
require("datejs");

function days_between(date1, date2) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}

export default {
  name: "emi_reducing_balance",

  data() {
    return {
      open: false,
      quote: { name: "Abbas" },
      name: "",
      selection: 0,
      Amount: 100000,
      Tenure: 20,
      RateOfInterest: 41.3145,
      ProcessingFeesPercentage: 3,
      InsuranceFeesPercentage: 2,
      disbursement_date_menu: false,
      firstemi_date_menu: false,
      DisbursementDate: new Date().toISOString().substr(0, 10),
      FirstEMIDate: new Date().toISOString().substr(0, 10),
      EMI: 0,
      repayments: [],
    };
  },
  methods: {
    calculate_result(n) {
      this.selection = n;
      if (this.selection === 0) {
        this.EMI = finance.PMT(
          this.MonthlyInterestRate,
          this.Tenure,
          this.Amount * -1
        );
      } else if (this.selection === 1) {
        var roit = finance.RATE(this.Tenure, this.EMI, this.Amount * -1) * 12;
        this.RateOfInterest = roit.toFixed(6) * 100;
      } else if (this.selection === 3) {
        var amountt = finance.PV(
          this.MonthlyInterestRate,
          this.Tenure,
          this.EMI * -1
        );
        this.Amount = amountt;
      } else if (this.selection === 2) {
        var tenuret = finance.NPER(
          this.MonthlyInterestRate,
          this.EMI,
          this.Amount * -1
        );
        this.Tenure = Math.round(tenuret);
      }
    },
    schedule() {
      this.repayments = [];
      var fd = Date.parse(this.FirstEMIDate)
        .add(-1)
        .months();
      var ramt = this.Amount;
      for (let i = 1; i <= this.Tenure; i++) {
        var rppt =
          finance.PPMT(this.MonthlyInterestRate, i, this.Tenure, this.Amount) *
          -1;
        var repayment = {
          idx: i,
          payment_date: fd
            .add(1)
            .months()
            .toString("dd-MMM-yyyy"),
          rpt: this.EMI,
          rpp: rppt,
          rpi:
            finance.IPMT(
              this.MonthlyInterestRate,
              i,
              this.Tenure,
              this.Amount
            ) * -1,
          rpb: ramt - rppt,
        };
        this.repayments.push(repayment);
        ramt = ramt - rppt;
      }

      return;
    },
    print_document() {
      this.schedule();
      this.quote["name"] = this.name;
      this.quote["principal"] = this.Amount;
      this.quote["emi"] = this.EMI;
      this.quote["total_repayment"] = this.TotalRepayment;
      this.quote["monthly_interest"] = this.FixedMonthlyInterest.toFixed(2);
      this.quote["tenure"] = this.Tenure;
      this.quote["has_residual"] = this.HasResidual;
      this.quote["residual_days"] = this.ResidualDays;
      this.quote["residual_start_date"] = this.DisbursementDateStr;
      this.quote["residual_end_date"] = this.ResidualEndDate;
      this.quote["repayments"] = this.repayments;
      this.quote["residual_interest"] = this.ResidualInterest;
      this.quote["disbursement_date"] = this.DisbursementDateStr;
      this.quote["first_due_date"] = Date.parse(this.FirstEMIDate).toString("dd-MMM-yyyy");
      this.quote["last_due_date"] = this.LastEMIDate;
      this.open = true;
      localStorage.setItem("quote", JSON.stringify(this.quote));
      let route = this.$router.resolve({
        path: "/schedule",
      });
      window.open(route.href, "_blank");
    },
  },
  computed: {
    ProcessingFees() {
      return (this.amount * this.ProcessingFeesPercentage) / 100;
    },
    InsuranceFees() {
      return (this.amount * this.InsuranceFeesPercentage) / 100;
    },
    MonthlyInterestRate() {
      return this.RateOfInterest / 100 / 12;
    },
    TotalRepayment() {
      return Math.round(this.EMI) * this.Tenure;
    },
    FixedMonthlyInterest() {
      return (
        ((this.TotalRepayment - this.Amount) / this.Tenure / this.Amount) * 100
      );
    },
    DisbursementToFirstEmiDays() {
      const firstDate = Date.parse(this.FirstEMIDate);
      const secondDate = Date.parse(this.DisbursementDate);
      const diffDays = days_between(firstDate, secondDate);
      return diffDays;
    },
    HasResidual() {
      if (this.ResidualDays > 0) {
        return true;
      } else {
        return false;
      }
    },
    ResidualDays() {
      const firstDate = Date.parse(this.FirstEMIDate);
      const secondDate = Date.parse(this.DisbursementDate);
      const diffMonths = firstDate.getMonth() - secondDate.getMonth();
      if (diffMonths > 1) {
        firstDate.setMonth(firstDate.getMonth() - 1, 1);
        const diffDays = days_between(firstDate, secondDate);
        return diffDays;
      } else {
        return 0;
      }
    },
    DisbursementDateStr(){
      return Date.parse(this.DisbursementDate).toString("dd-MMM-yyyy");
    },
    ResidualEndDate() {
      if (this.HasResidual) {
        const firstDate = Date.parse(this.FirstEMIDate);
        firstDate.setMonth(firstDate.getMonth() - 1, 1);
        return firstDate.toString("dd-MMM-yyyy");
      } else {
        return null;
      }
    },
    ResidualInterest(){
      if(this.HasResidual){
        var daily_interest = this.FixedMonthlyInterest/30/100;
        return Math.round(this.Amount * daily_interest * this.ResidualDays);
      }
      else{
        return 0;
      }
    },
    LastEMIDate(){
      return this.repayments[this.repayments.length - 1].payment_date;
    }
  },
  components: {
    VuetifyMoney,
    AmazonMonitize,
  },
  created() {
    this.calculate_result(0);
  },
};
</script>
